
import { $gettext, $ngettext } from 'vue-gettext';
import { actionTooltip, Header, toDate } from '@/components/table/BpTable';
import { byKey, Order } from '@/utils/array';
import { Component, Product, useVersionStore, Version, VersionNumber } from '@/stores/version';
import { computed, defineComponent, ref, watch } from 'vue';
import { cordawareIcon } from '@/components/icon/BpIcon';
import { currentLanguage, currentLanguageISOString, getTranslated, Translated } from '@/translation';
import { KeyOf } from '@/utils/object';
import { translatedUnitEnumeration } from '@/utils/string';
import { useDownloadStore } from '@/stores/download';
import { useRouter } from 'vue-router';
import BpToast from '@/components/toast/BpToasts';

export default defineComponent({
  name: 'version-overview',
  setup() {
    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();

    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const versionStore = useVersionStore();

    ///-------------------------------------------------------------------
    /// LANGUAGE
    ///-------------------------------------------------------------------

    const lang = computed(() => currentLanguageISOString());

    ///-------------------------------------------------------------------
    /// EDIT
    ///-------------------------------------------------------------------

    function updateVersion(id: string | string[]) {
      if (Array.isArray(id)) {
        id = id[0];
      }
      const version = versionStore.getById(id);
      if (!version) {
        return;
      }
      const isProduct = version.doc_type === 'version_product';
      const isComponent = version.doc_type === 'version_component';
      router.push({
        name: isProduct ? 'admin.version.product.edit' : (isComponent ? 'admin.version.component.edit' : 'admin.version.number.edit'),
        params: {
          id
        }
      });
    }

    ///-------------------------------------------------------------------
    /// DELETE CONFIRMATION
    ///-------------------------------------------------------------------

    const confirmation = ref(false);
    const confirmDelete = ref<{
      products: Product[],
      components: Component[],
      versionNumbers: VersionNumber[],
    }>({
      products: [],
      components: [],
      versionNumbers: []
    })
    const confirmSelectedIds = ref<string[]>([]);

    function showDeleteConfirmation(ids: string | string[]) {
      if (!Array.isArray(ids)) {
        ids = [ids];
      }
      confirmSelectedIds.value = ids;
      const versions = versionStore.getFlatSubtree(ids);
      confirmDelete.value.products = (versions.filter(version => version.doc_type === 'version_product') as Product[])
        .sort(byKey(`name.${currentLanguageISOString()}`));
      confirmDelete.value.components = (versions.filter(version => version.doc_type === 'version_component') as Component[])
        .sort(byKey(`name.${currentLanguageISOString()}`));
      confirmDelete.value.versionNumbers = (versions.filter(version => version.doc_type === 'version_number') as VersionNumber[])
        .sort(byKey('version'));
      confirmation.value = true;
    }

    ///-------------------------------------------------------------------
    /// DELETE
    ///-------------------------------------------------------------------

    async function deleteVersion() {
      const productIDs = confirmDelete.value.products.map(product => product._id);
      const componentIDs = confirmDelete.value.components.map(component => component._id);
      const versionNumberIDs = confirmDelete.value.versionNumbers.map(versionNumber => versionNumber._id);
      const response = await versionStore.delete([
        ...productIDs,
        ...componentIDs,
        ...versionNumberIDs,
      ]);

      if (response?.success) {
        const products = response.data?.filter(doc => doc.doc_type === 'version_product') || [];
        const components = response.data?.filter(doc => doc.doc_type === 'version_component') || [];
        const versionNumbers = response.data?.filter(doc => doc.doc_type === 'version_number') || [];

        if (products && components && versionNumbers) {
          if (products.length === productIDs.length && components.length === componentIDs.length && versionNumbers.length === versionNumberIDs.length) {
            BpToast.show({
              color: 'green',
              title: $gettext('%{list} successfully deleted', { list: translatedUnitEnumeration({
                products: [productIDs.length, products.length],
                components: [componentIDs.length, components.length],
                versionnumbers: [versionNumberIDs.length, versionNumbers.length],
              }, { includeCount: false }) }),
              content: $ngettext(
                '<strong>%{list}</strong> was successfully deleted.',
                '<strong>%{list}</strong> were successfully deleted.',
                products.length + components.length + versionNumbers.length,
                {
                  list: translatedUnitEnumeration({
                    products: [productIDs.length, products.length],
                    components: [componentIDs.length, components.length],
                    versionnumbers: [versionNumberIDs.length, versionNumbers.length],
                  })
                }),
              icon: 'check',
            });
          } else {
            BpToast.show({
              color: 'red',
              title: $gettext('Failed to delete %{list}', { list: translatedUnitEnumeration({
                products: [productIDs.length, products.length],
                components: [componentIDs.length, components.length],
                versionnumbers: [versionNumberIDs.length, versionNumbers.length],
              }, { difference: true }) }),
              content: [
                $ngettext(
                  '<strong>%{list}</strong> was successfully deleted.',
                  '<strong>%{list}</strong> were successfully deleted.',
                  products.length + components.length + versionNumbers.length,
                  {
                    list: translatedUnitEnumeration({
                      products: [productIDs.length, products.length],
                      components: [componentIDs.length, components.length],
                      versionnumbers: [versionNumberIDs.length, versionNumbers.length],
                    })
                  }),
                $gettext(
                  'Failed to delete <strong>%{list}</strong>.',
                  {
                    list: translatedUnitEnumeration({
                      products: [productIDs.length, products.length],
                      components: [componentIDs.length, components.length],
                      versionnumbers: [versionNumberIDs.length, versionNumbers.length],
                    }, { difference: true })
                  }),
              ].join('<br>'),
              icon: 'xmark',
            });
          }
        } else {
          BpToast.show({
            color: 'rose',
            title: 'You should not see this!',
            content: 'Something went terribly wrong!',
            icon: 'triangle-exclamation',
          });
        }
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Failed to delete %{list}', { list: translatedUnitEnumeration({
            products: productIDs.length,
            components: componentIDs.length,
            versionnumbers: versionNumberIDs.length,
          }, { includeCount: false }) }),
          content: $gettext('Failed to delete <strong>%{list}</strong>.', { list: translatedUnitEnumeration({
            products: productIDs.length,
            components: componentIDs.length,
            versionnumbers: versionNumberIDs.length,
          }) }),
          icon: 'xmark',
        });
      }
      confirmDelete.value = {
        products: [],
        components: [],
        versionNumbers: [],
      };
      confirmation.value = false;
    }

    ///-------------------------------------------------------------------
    /// HEADERS
    ///-------------------------------------------------------------------

    const downloadStore = useDownloadStore();

    const headers = ref<Header[]>([]);
    watch(() => [currentLanguage.value, downloadStore.isLoading()], () => {
      if (!downloadStore.loaded || downloadStore.isLoading()) { return }
      headers.value = [
        {
          _id: 'name.' + lang.value,
          name: $gettext('Name'),
          width: '2fr',
          icon: (version: Version) => versionStore.getIcon(version),
          transform: (version: Version) => versionStore.getName(version),
        },
        {
          _id: 'date',
          name: $gettext('Release date'),
          transform: (version: Version) => 'date' in version ? toDate(version, version.date) : '',
        },
        {
          _id: 'meta',
          name: $gettext('Meta information'),
          width: '2fr',
          transform: (version: Version, meta: Version['meta']) => version.doc_type !== 'version_number'
            ? Object.entries(meta)
                .sort(([a,], [b,]) => a < b ? -1 : 1)
                .map(([,val]: [string, Translated<string>]) => getTranslated(val))
                .join(', ')
            : Object.entries(meta)
                .sort(([a,], [b,]) => a < b ? -1 : 1)
                .filter(([key,]) => !!versionStore.getTranslatedMetaKey(key))
                .map(([key, val]: [string, string]) => versionStore.getTranslatedMetaKey(key) + ': ' + val)
                .join(',\n'),
        },
        {
          _id: 'version',
          name: $gettext('File uploaded'),
          filter: [
            {
              _id: '_true',
              name: $gettext('Yes'),
              icon: { icon: 'check', color: 'green' },
              detect: (version: Version) => version.doc_type === 'version_number' && downloadStore.getFiles().map(file => file.version).includes(version._id),
            },
            {
              _id: 'false',
              name: $gettext('No'),
              icon: { icon: 'xmark', color: 'red' },
              detect: (version: Version) => version.doc_type !== 'version_number' || !downloadStore.getFiles().map(file => file.version).includes(version._id),
            }
          ],
          notSortable: true,
          textAlign: 'center',
          transform: (version: Version) => version.doc_type === 'version_number' ? downloadStore.getFiles().map(file => file.version).includes(version._id) : '',
        },
      ];
    }, { immediate: true })


    ///-------------------------------------------------------------------
    /// EXPANDED
    ///-------------------------------------------------------------------

    const expanded = ref<Set<string>>();

    watch(() => versionStore.isLoading(), () => {
      if (!versionStore.loaded || versionStore.isLoading() || expanded.value) { return }
      expanded.value = new Set([
        ...versionStore.getProducts(),
        ...versionStore.getComponents(),
      ].filter(el => versionStore.hasChildren(el._id)).map(productOrComponent => productOrComponent._id))
    }, { immediate: true });

    function updateExpanded(value: Set<string>) {
      expanded.value = value;
    }

    ///-------------------------------------------------------------------
    /// DRAGGING
    ///-------------------------------------------------------------------

    function dragenter(targetId: string, sourceId: string) {
      document.querySelectorAll('[data-table-row-id]').forEach(el => el.classList.remove('bp-version-overview__highlight'))
      if (targetId !== sourceId && versionStore.getValidParentProductsOrComponents(sourceId).map(productOrComponent => productOrComponent._id).includes(targetId)) {
        const el = document.querySelector(`[data-table-row-id="${targetId}"]`) as HTMLElement;
        el.classList.add('bp-version-overview__highlight');
      }
    }

    function dragend(targetId: null, sourceId: string) {
      if (targetId !== sourceId) {
        const el = document.querySelector(`[data-table-row-id="${targetId}"]`) as HTMLElement;
        el.classList.remove('bp-version-overview__highlight');
      }
    }

    function drop(parent: string, id: string) {
      // Remove highlight styling
      document.querySelectorAll('[data-table-row-id]').forEach(el => el.classList.remove('bp-version-overview__highlight'))

      // Move files
      const current = versionStore.getById(id);
      if (parent !== id && versionStore.getValidParentProductsOrComponents(id).map(productOrComponent => productOrComponent._id).includes(parent) && current && current.parent_id !== parent) {
        versionStore.move(id, parent)
      }
    }

    ///-------------------------------------------------------------------
    /// SORTING
    ///-------------------------------------------------------------------

    const sortKey = ref<KeyOf<Version> | ((item: Version) => KeyOf<Version>)>('date' as KeyOf<Version>);
    const sortOrder = ref<Order>('desc');

    function sort(sorting: Map<keyof Version, Order>) {
      const [key, order] = sorting.entries().next().value;
      sortKey.value = key;
      sortOrder.value = order;
    }

    ///-------------------------------------------------------------------
    /// QUERY
    ///-------------------------------------------------------------------

    function getQuery(selection: Set<string>) {
      const id = selection.size === 1 ? Array.from(selection)[0] : undefined;
      if (!id) {
        return {};
      }
      return versionStore.isProductOrComponent(id) ? { parent: id } : {}
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      actionTooltip,
      confirmation,
      confirmDelete,
      confirmSelectedIds,
      cordawareIcon,
      deleteVersion,
      dragend,
      dragenter,
      drop,
      expanded,
      getQuery,
      getTranslated,
      headers,
      showDeleteConfirmation,
      sort,
      sortKey,
      sortOrder,
      translatedUnitEnumeration,
      updateExpanded,
      updateVersion,
      versionStore,
    }
  }
})
