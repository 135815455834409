import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "bp-version-overview__table-prefix" }
const _hoisted_2 = { class: "bp-version-overview__table-prefix" }
const _hoisted_3 = { class: "bp-version-overview__table-prefix" }
const _hoisted_4 = { class: "bp-version-overview__table-prefix" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  key: 0,
  class: "bp-version-overview__confirmation-hint"
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = {
  key: 0,
  class: "bp-version-overview__confirmation-hint"
}
const _hoisted_11 = { key: 2 }
const _hoisted_12 = {
  key: 0,
  class: "bp-version-overview__confirmation-hint"
}
const _hoisted_13 = {
  key: 3,
  class: "bp-version-overview__confirmation-hint"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_view_header = _resolveComponent("bp-view-header")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_button = _resolveComponent("bp-button")!
  const _component_bp_table = _resolveComponent("bp-table")!
  const _component_bp_window = _resolveComponent("bp-window")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_bp_view_header, {
      class: "bp-version-overview__header",
      image: "/static/background/app_background_products_and_versions.jpg",
      icon: ['fad', 'v']
    }, {
      default: _withCtx(() => [
        _createVNode(_component_translate, null, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Product & version overview")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_bp_table, {
      data: _ctx.versionStore.getTree(_ctx.sortKey, _ctx.sortOrder),
      "default-sorting": ['date', 'desc'],
      expanded: _ctx.expanded,
      headers: _ctx.headers,
      loading: _ctx.versionStore.isLoading(),
      searchable: false,
      "onRows:dragend": _ctx.dragend,
      "onRows:dragenter": _ctx.dragenter,
      "onRows:drop": _ctx.drop,
      onSort: _ctx.sort,
      "onUpdate:expanded": _ctx.updateExpanded,
      "draggable-rows": "",
      "fill-height": ""
    }, {
      prefix: _withCtx(({ selection }) => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_bp_icon, { icon: "check" }),
          _createVNode(_component_translate, {
            "translate-plural": "<strong>%{count}</strong> selected versions",
            "translate-n": selection.size,
            "translate-params": { count: selection.size }
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createElementVNode("strong", null, "%{count}", -1),
              _createTextVNode(" selected version ")
            ])),
            _: 2
          }, 1032, ["translate-n", "translate-params"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_bp_icon, {
            icon: _ctx.cordawareIcon()
          }, null, 8, ["icon"]),
          _createVNode(_component_translate, {
            "translate-plural": "<strong>%{count}</strong> products",
            "translate-n": _ctx.versionStore.getProducts().length,
            "translate-params": { count: _ctx.versionStore.getProducts().length }
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createElementVNode("strong", null, "%{count}", -1),
              _createTextVNode(" product ")
            ])),
            _: 1
          }, 8, ["translate-n", "translate-params"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_bp_icon, { icon: ['far', 'gear'] }),
          _createVNode(_component_translate, {
            "translate-plural": "<strong>%{count}</strong> components",
            "translate-n": _ctx.versionStore.getComponents().length,
            "translate-params": { count: _ctx.versionStore.getComponents().length }
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createElementVNode("strong", null, "%{count}", -1),
              _createTextVNode(" component ")
            ])),
            _: 1
          }, 8, ["translate-n", "translate-params"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_bp_icon, { icon: ['far', 'v'] }),
          _createVNode(_component_translate, {
            "translate-plural": "<strong>%{count}</strong> version numbers",
            "translate-n": _ctx.versionStore.getVersionNumbers().length,
            "translate-params": { count: _ctx.versionStore.getVersionNumbers().length }
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createElementVNode("strong", null, "%{count}", -1),
              _createTextVNode(" version number ")
            ])),
            _: 1
          }, 8, ["translate-n", "translate-params"])
        ])
      ]),
      toolbar: _withCtx(({ selection }) => [
        _createVNode(_component_bp_button, {
          icon: "arrows-rotate",
          color: "yellow",
          onClick: _ctx.versionStore.reload
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("Refresh")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_bp_button, {
          icon: _ctx.cordawareIcon(),
          color: "green",
          to: { name: 'admin.version.product.new', query: _ctx.getQuery(selection) }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode("New product")
              ])),
              _: 1
            })
          ]),
          _: 2
        }, 1032, ["icon", "to"]),
        _createVNode(_component_bp_button, {
          icon: ['far', 'gear'],
          color: "green",
          to: { name: 'admin.version.component.new', query: _ctx.getQuery(selection) }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode("New component")
              ])),
              _: 1
            })
          ]),
          _: 2
        }, 1032, ["to"]),
        _createVNode(_component_bp_button, {
          icon: ['far', 'v'],
          color: "green",
          to: { name: 'admin.version.number.new', query: _ctx.getQuery(selection) }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode("New version number")
              ])),
              _: 1
            })
          ]),
          _: 2
        }, 1032, ["to"]),
        _createVNode(_component_bp_button, {
          icon: ['far', 'pencil'],
          color: "light-blue",
          disabled: selection.size !== 1,
          onClick: ($event: any) => (_ctx.updateVersion(Array.from(selection)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode("Edit")
              ])),
              _: 1
            })
          ]),
          _: 2
        }, 1032, ["disabled", "onClick"]),
        _createVNode(_component_bp_button, {
          style: {"margin-left":"auto"},
          icon: ['far', 'trash-can'],
          color: "red",
          disabled: selection.size === 0,
          onClick: ($event: any) => (_ctx.showDeleteConfirmation(Array.from(selection)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode("Delete")
              ])),
              _: 1
            })
          ]),
          _: 2
        }, 1032, ["disabled", "onClick"])
      ]),
      actions: _withCtx(({ item }) => [
        _withDirectives(_createVNode(_component_bp_icon, {
          icon: ['far', 'pencil'],
          onClick: ($event: any) => (_ctx.updateVersion(item._id))
        }, null, 8, ["onClick"]), [
          [_directive_tooltip, _ctx.actionTooltip(item.doc_type === 'version_product' ? _ctx.$gettext('Edit product') : item.doc_type === 'version_component' ? _ctx.$gettext('Edit component') : _ctx.$gettext('Edit version number'))]
        ]),
        _withDirectives(_createVNode(_component_bp_icon, {
          icon: ['far', 'trash-can'],
          color: "red",
          onClick: ($event: any) => (_ctx.showDeleteConfirmation(item._id))
        }, null, 8, ["onClick"]), [
          [_directive_tooltip, _ctx.actionTooltip(item.doc_type === 'version_product' ? _ctx.$gettext('Delete product') : item.doc_type === 'version_component' ? _ctx.$gettext('Delete component') : _ctx.$gettext('Delete version number'))]
        ])
      ]),
      _: 1
    }, 8, ["data", "expanded", "headers", "loading", "onRows:dragend", "onRows:dragenter", "onRows:drop", "onSort", "onUpdate:expanded"]),
    _createVNode(_component_bp_window, {
      type: "warning",
      modelValue: _ctx.confirmation,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.confirmation) = $event))
    }, {
      header: _withCtx(() => [
        _createVNode(_component_translate, { tag: "h2" }, {
          default: _withCtx(() => _cache[13] || (_cache[13] = [
            _createTextVNode("Delete confirmation")
          ])),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_translate, {
          "translate-params": { list: _ctx.translatedUnitEnumeration({
          products: _ctx.confirmDelete.products.length,
          components: _ctx.confirmDelete.components.length,
          versionNumbers: _ctx.confirmDelete.versionNumbers.length,
        }) }
        }, {
          default: _withCtx(() => _cache[14] || (_cache[14] = [
            _createTextVNode("Do you really want to delete "),
            _createElementVNode("strong", null, "%{list}", -1),
            _createTextVNode("?")
          ])),
          _: 1
        }, 8, ["translate-params"]),
        (_ctx.confirmDelete.products.length > 0)
          ? (_openBlock(), _createElementBlock("table", _hoisted_5, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, [
                    _createVNode(_component_translate, {
                      "translate-plural": "%{count} products",
                      "translate-n": _ctx.confirmDelete.products.length,
                      "translate-params": { count: _ctx.confirmDelete.products.length }
                    }, {
                      default: _withCtx(() => _cache[15] || (_cache[15] = [
                        _createTextVNode(" %{count} product ")
                      ])),
                      _: 1
                    }, 8, ["translate-n", "translate-params"])
                  ])
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.confirmDelete.products, (product) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: product._id
                  }, [
                    _createElementVNode("td", null, [
                      _createElementVNode("span", {
                        innerHTML: _ctx.versionStore.getName(product)
                      }, null, 8, _hoisted_6),
                      (!_ctx.confirmSelectedIds.includes(product._id))
                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, " *"))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.confirmDelete.components.length > 0)
          ? (_openBlock(), _createElementBlock("table", _hoisted_8, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, [
                    _createVNode(_component_translate, {
                      "translate-plural": "%{count} components",
                      "translate-n": _ctx.confirmDelete.components.length,
                      "translate-params": { count: _ctx.confirmDelete.components.length }
                    }, {
                      default: _withCtx(() => _cache[16] || (_cache[16] = [
                        _createTextVNode(" %{count} component ")
                      ])),
                      _: 1
                    }, 8, ["translate-n", "translate-params"])
                  ])
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.confirmDelete.components, (component) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: component._id
                  }, [
                    _createElementVNode("td", null, [
                      _createElementVNode("span", {
                        innerHTML: _ctx.versionStore.getName(component)
                      }, null, 8, _hoisted_9),
                      (!_ctx.confirmSelectedIds.includes(component._id))
                        ? (_openBlock(), _createElementBlock("span", _hoisted_10, " *"))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.confirmDelete.versionNumbers.length > 0)
          ? (_openBlock(), _createElementBlock("table", _hoisted_11, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, [
                    _createVNode(_component_translate, {
                      "translate-plural": "%{count} version numbers",
                      "translate-n": _ctx.confirmDelete.versionNumbers.length,
                      "translate-params": { count: _ctx.confirmDelete.versionNumbers.length }
                    }, {
                      default: _withCtx(() => _cache[17] || (_cache[17] = [
                        _createTextVNode(" %{count} version number ")
                      ])),
                      _: 1
                    }, 8, ["translate-n", "translate-params"])
                  ])
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.confirmDelete.versionNumbers, (versionNumber) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: versionNumber._id
                  }, [
                    _createElementVNode("td", null, [
                      _createTextVNode(_toDisplayString('version' in versionNumber ? versionNumber.version : ''), 1),
                      (!_ctx.confirmSelectedIds.includes(versionNumber._id))
                        ? (_openBlock(), _createElementBlock("span", _hoisted_12, " *"))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.confirmSelectedIds.length < Object.values(_ctx.confirmDelete).flat().length)
          ? (_openBlock(), _createElementBlock("p", _hoisted_13, [
              _cache[19] || (_cache[19] = _createTextVNode("* ")),
              _createVNode(_component_translate, {
                tag: "em",
                style: {"font-weight":"normal"},
                "translate-plural": "These <strong>%{list}</strong> are included in the selection and are therefore also deleted.",
                "translate-n": Object.values(_ctx.confirmDelete).flat().filter(version => !_ctx.confirmSelectedIds.includes(version._id)).length,
                "translate-params": { list: _ctx.translatedUnitEnumeration({
          products: _ctx.confirmDelete.products.filter(product => !_ctx.confirmSelectedIds.includes(product._id)).length,
          components: _ctx.confirmDelete.components.filter(component => !_ctx.confirmSelectedIds.includes(component._id)).length,
          versionNumbers: _ctx.confirmDelete.versionNumbers.filter(versionNumber => !_ctx.confirmSelectedIds.includes(versionNumber._id)).length,
        }, { includeCount: false }) }
              }, {
                default: _withCtx(() => _cache[18] || (_cache[18] = [
                  _createTextVNode("This "),
                  _createElementVNode("strong", null, "%{list}", -1),
                  _createTextVNode(" is included in the selection and is therefore also deleted.")
                ])),
                _: 1
              }, 8, ["translate-n", "translate-params"])
            ]))
          : _createCommentVNode("", true)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_bp_button, {
          icon: "check",
          color: "green",
          onClick: _ctx.deleteVersion
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[20] || (_cache[20] = [
                _createTextVNode("Confirm")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_bp_button, {
          icon: "xmark",
          color: "red",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.confirmation = false))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[21] || (_cache[21] = [
                _createTextVNode("Cancel")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}